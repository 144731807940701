@font-face {
  font-family: 'bebas_regular';
  src:  url('./fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'gloock_regular';
  src:  url('./fonts/Gloock-Regular.ttf') format('truetype');
}


* {
  padding: 0;
  margin: 0;
}

:root{
  scroll-behavior: smooth;

  --maxWidth: 700px;

  --color1: black;
  --color2: rgba(255, 255, 255, 0.9);
  --color3: rgba(255, 255, 255, 0.8);
  --color4: rgba(255,255,255,0.4);
  --color5: rgba(16, 16, 16, 1.0);
  --color6: #e9292f;
  --color7: rgba(52, 52, 52, 1);

  --gap05: 5px;
  --gap1: 10px;
  --gap2: 20px;
  --gap3: 30px;
  --gap4: 50px;
  --padding-big: 50px;

  --confirmBtnLength: 200px;

  --backgroundPicWidth: 2311.5px;
  --backgroundPicHeight: 100vh;
  --backgroundPicMaxHeight: 1300px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color1);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






/* CONTAINERS UNIVERSAL  */
/* flex / gaps / padding / margin */
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.gap-05 {
  gap: var(--gap05);
}

.gap-1 {
  gap: var(--gap1);
}

.gap-2 {
  gap: var(--gap2);
}

.gap-3 {
  gap: var(--gap3);
}

.gap-4 {
  gap: var(--gap4);
}

.p-1 {
  padding: var(--gap1);
}

.pt-1{
  padding-top: var(--gap1);
}

.pb-1{
  padding-bottom: var(--gap1);
}

.pl-1 {
  padding-left: var(--gap1);
}

.pl-2 {
  padding-left: var(--gap2);
}

.pl-3 {
  padding-left: var(--gap3);
}

.pr-1 {
  padding-right: var(--gap1);
}

.pt-05{
  padding-top: var(--gap05);
}

.pb-05{
  padding-bottom: var(--gap05);
}

.pt-big {
  padding-top: var(--padding-big);
}

.mt-a {
  margin-top: auto;
}

.mt-1 {
  margin-top:  var(--gap1);
}

.mt-2 {
  margin-top:  var(--gap2);
}

.mt-3 {
  margin-top:  var(--gap3);
}

.mt-4 {
  margin-top:  var(--gap4);
}

/*  SIZE  */
.width-fit {
  width: fit-content;
}

.width-full {
  width: 100%;
}

.max-width-800 {
  width: 100%;
  max-width: 800px;
}



/*BUTTONS*/
.chooseBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: rgba(0,0,0,0.5);
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
}

.sendBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 600;
}

.vysledkyBtn1 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.vysledkyBtn2 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color4);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  display: flex;
}

.nextBtn1 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  aspect-ratio: 1 / 1;
}

.nextBtn2 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor:not-allowed;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  aspect-ratio: 1 / 1;
  opacity: 0.2;
}

.csvBtn {
  padding: var(--gap05);
  border-radius: 5px;
  border: none;
  background: transparent;
  border: 2px solid var(--color2);
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (min-width: 400px) {
  .vysledkyBtn1 {
    font-size: 14px;
    padding: var(--gap1);
  }

  .vysledkyBtn2 {
    font-size: 14px;
    padding: var(--gap1);
  }
}



/* BORDERS */

.border-bottom-1 {
  border-bottom: 1px solid var(--color4);
  border-bottom-style: dashed;
}

/* BARVY */

.background-1 {
  background: var(--color1);
}

.background-2 {
  background: var(--color2);
}

.background-3 {
  background: var(--color3);
}

.background-4 {
  background: var(--color4);
}

/* texty */
.white-text {
  color: var(--color2);
}

.error {
  color: rgb(200, 0, 0);
  font-weight: 700;
}

a{
  text-decoration: none;
  color: initial;
}


/* TEXTY */
.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* SCROLLING */
.scrollMarginTop-1 {
  scroll-margin-top: var(--gap2);
}




/* CUSTOM */
.main-container {
  width: 100%;
  height: var(--backgroundPicHeight);
  max-height: var(--backgroundPicMaxHeight);
  background: "rgba(0,0,250,0.5";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.menu-1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap1);

  height: 440px;
  transform: translate(484px, -70px);
  /*background: rgba(0,255,0,0.5);*/
}

.menu-2-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transform: translate(372px, 104px);
  /*background: rgba(0,255,0,0.5);*/
}

.menu-3-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap1);
  
  transform: translate(22px, -200px);
  /*background: rgba(0,255,0,0.5);*/
}

.menu-4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap1);
  
  transform: translate(104px, -120px);
  /*background: rgba(0,255,0,0.5);*/
}

.menu-5-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap1);
  
  transform: translate(116px, -145px);
  /*background: rgba(0,255,0,0.5);*/
}

.table-menu {
  position: absolute;
  transform: translate(75px, 0px);
}

.table-menu-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap1);
  width: fit-content;
  transform: translate(75px, -5px);
  position: absolute;
}





/* fonts */
.menu-text-header {
  font-family: "gloock_regular";
  font-size: 40px;
  color: rgb(71, 72, 71);
}

.menu-text-header-2 {
  font-family: "gloock_regular";
  font-weight: 100;
  font-size: 55px;
  color: rgba(220,220,220,0.8);
}

.menu-text-header-3 {
  font-family: "gloock_regular";
  font-size: 40px;
  color: rgb(41, 42, 41);
}

.menu-text {
  font-family: "bebas_regular";
  font-size: 60px;
  line-height: 1;
  padding-top: 5px;
}

.menu-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23996A4BFF' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 14px;

}

.menu-text-container:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}


.menu-text-2 {
  font-family: "bebas_regular";
  font-size: 65px;
  line-height: 1;
  padding-top: 5px;
  letter-spacing: 5px;
}

.menu-text-2-main {
  font-family: "bebas_regular";
  font-size: 55px;
  line-height: 1;
  padding-top: 5px;
  letter-spacing: 0px;
}

.menu-text-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  color: rgb(41, 42, 41);
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='white' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 14px;
  padding: 5px;
}

.menu-text-container-2:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}

.menu-text-container-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px dotted rgb(152, 102, 70);
  border-radius: 10px;
  width: 360px;
  color: rgb(41, 42, 41);
  cursor: pointer;
}

.menu-text-container-3:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}

.locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  
  transform: translate(673px, -86px);
  /*background: rgba(0,255,0,0.5);*/
}

.locations-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 130px;
  color: rgb(169, 160, 85);
  cursor: pointer;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23986646FF' stroke-width='8' stroke-dasharray='2.4' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.locations-text-container:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}


.locations-menu-text {
  font-family: "bebas_regular";
  font-size: 26px;
  line-height: 1.2;
  padding-top: 5px;
}


.locations-text-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: none;
  width: 142px;
  color: rgb(207, 150, 97);
  cursor: pointer;
  background: transparent;


  border-radius: 12px;



}

.locations-text-container-2:hover {
  /*border: 7px dotted rgb(207, 150, 97, 1);*/
  /*color: rgb(247, 247, 248);*/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D1945FFF' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.locations-menu-text-2 {
  font-family: "bebas_regular";
  font-size: 42px;
  line-height: 1;
  padding-top: 5px;
}



.production-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  
  transform: translate(227px, -315px);
  /*background: rgba(0,255,0,0.5);*/
}

.production-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  width: 125px;
  color: rgb(169, 160, 85);
  cursor: pointer;
  padding: 2px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23986646FF' stroke-width='8' stroke-dasharray='2.4' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;

}

.production-text-container:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}

.production-text-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  opacity: 1;
  background: transparent;
  color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D1945FFF' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='8' opacity='0.5' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;  
  padding: 1px;
}

.production-text-container-2:hover {
  color: rgb(247, 247, 248);
}


.production-menu-text-2 {
  font-family: "bebas_regular";
  font-size: 50px;
  line-height: 1;
  padding-top: 5px;
  background-image: url(pictures/bg-text.png);
  opacity: 0.8;
  background-size: cover;
  -webkit-background-clip: text;
}

.wardrobe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  transform: translate(422px, 43px);
  /*background: rgba(0,255,0,0.5);*/
}


.wardrobe-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  width: 310px;
  color: rgb(71, 72, 71);
  cursor: pointer;
  background: transparent;
  padding: 5px;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23996A4BFF' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 14px;

}



.wardrobe-text-container:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}


.wardrobe-text {
  font-family: "bebas_regular";
  font-size: 48px;
  line-height: 1;
  padding-top: 5px;
}


.wardrobe-container-2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  
  transform: translate(75px, -25px);
  /*background: rgba(0,255,0,0.5);*/
}

.wardrobe-text-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  width: 125px;
  color: rgb(169, 160, 85);
  cursor: pointer;
  padding: 2px;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23986646FF' stroke-width='8' stroke-dasharray='2.4' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.wardrobe-text-container-2:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}


.casting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  /*transform: translate(450px, -25px);*/
  /*background: rgba(0,255,0,0.5);*/
}

.casting-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 5px;
  width: 280px;
  color: rgb(71, 72, 71);
  cursor: pointer;
  background: transparent;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23996A4BFF' stroke-width='9' stroke-dasharray='4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 14px;
}

.casting-text {
  font-family: "bebas_regular";
  font-size: 55px;
  line-height: 1;
  padding-top: 5px;
}

.casting-text-container:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}


.casting-container-2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  rotate: 1deg;

  transform: translate(15px, -245px);
  /*background: rgba(0,255,0,0.5);*/
}


.casting-text-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 3px;
  width: 120px;
  color: rgb(169, 160, 85);
  cursor: pointer;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23986646FF' stroke-width='8' stroke-dasharray='2.4' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.casting-text-container-2:hover {
  color: rgb(247, 247, 248);
  /*background: rgba(152, 102, 70, 1);*/
}




/* background pic */
.background-pic {
  position: fixed;
  width: var(--backgroundPicWidth);
  height: var(--backgroundPicMaxHeight);
}

.background-pic img {
  object-fit: cover;
}


/* LOGIN BTN  */
.loadingImg {
  animation: loadingSpin 1s infinite linear;

}

@keyframes loadingSpin {
  0%{
    rotate: 0deg;
  }
  100%{
    rotate: 360deg;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  width: var(--backgroundPicWidth);
  height: var(--backgroundPicMaxHeight);
  
}

.logoutbtn-container {
  position: absolute;
  transform: translate(810px, -448px);
  z-index: 200;
  opacity: 0.8;
}

.logoutbtn-container button{
  font-family: 'bebas_regular';
  /*font-family: 'gloock_regular';*/
  padding: 10px 30px 7px;
  background: rgba(210, 210, 205, 0);
  font-size: 30px;
  line-height: 1;
  border: none;
  color: rgb(71, 72, 71);
  cursor: pointer;
  border-radius: 8px;
}

.logoutbtn-container:hover {
  opacity: 1;
}

.logoutbtn-container-admin {
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 200;
  opacity: 0.8;
}

.logoutbtn-container-admin button{
  font-family: 'bebas_regular';
  /*font-family: 'gloock_regular';*/
  background: rgba(210, 210, 205, 0);
  font-size: 30px;
  line-height: 1;
  border: none;
  color: rgb(71, 72, 71);
  cursor: pointer;
  border-radius: 8px;
}


.backbtn-container {
  position: absolute;
  transform: translate(812px, -450px);
  z-index: 200;
  opacity: 0.8;
}

.backbtn-container button {
  font-family: 'bebas_regular';
  /*font-family: 'gloock_regular';*/
  padding: 5px 30px 0px;
  background: rgba(210, 210, 205, 0);
  /*font-size: 30px;
  color: rgb(71, 72, 71);
  line-height: 1;*/
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.backbtn-container:hover {
  opacity: 1;
}


.filetable-close {
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.2s;

  position: absolute;
  z-index: 20;
  transform: translate(1010px, -574px);
}

.filetable-close:hover {
  opacity: 1;
}